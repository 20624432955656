import { IWorkflowQuery } from "../models/workflow/query";
import { ICreateWorkflow, IUpdateWorkflow } from "../models/workflow/request";
import { IWorkflow, IWorkflowDetails } from "../models/workflow/response";
import IBaseListingResponse from "../utils/api/base-listing-response";
import { HttpClient, RequestParams } from "./http-client";

export class WorkflowService<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  getAllWorkflows = (query: IWorkflowQuery, params?: RequestParams) =>
    this.http.request<IBaseListingResponse<IWorkflow>>({
      path: `/workflows`,
      method: "GET",
      query,
      secure: true,
      ...params,
    });

  getWorkflow = (id: number, params?: RequestParams) =>
    this.http.request<IWorkflowDetails>({
      path: `/workflows/${id}`,
      method: "GET",
      secure: true,
      ...params,
    });

  createWorkflow = (data: ICreateWorkflow, params?: RequestParams) =>
    this.http.request<IWorkflow>({
      path: "/workflows",
      method: "POST",
      secure: true,
      body: data,
      ...params,
    });

  updateWorkflow = (id: number, data: IUpdateWorkflow, params?: RequestParams) =>
    this.http.request<IWorkflow>({
      path: `/workflows/${id}`,
      method: "PUT",
      body: data,
      secure: true,
      ...params,
    });

  deleteWorkflow = (id: number, params?: RequestParams) =>
    this.http.request<IWorkflow>({
      path: `/workflows/${id}`,
      method: "DELETE",
      secure: true,
      ...params,
    });
}

export default WorkflowService;
