import { IUserQuery } from "../models/users/query";
import { ICreateUser } from "../models/users/request";
import { IUser } from "../models/users/response";
import IBaseListingResponse from "../utils/api/base-listing-response";
import { HttpClient, RequestParams } from "./http-client";

export class SupervisorsService<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  getAllSupervisors= (query: IUserQuery, params?: RequestParams) =>
    this.http.request<IBaseListingResponse<any>>({
      path: `/supervisors`,
      method: "GET",
      query,
      secure: true,
      ...params,
    });

  getSupervisor = (id: number, params?: RequestParams) =>
    this.http.request<IUser>({
      path: `/supervisors/${id}`,
      method: "GET",
      secure: true,
      ...params,
    });

  createSupervisor = (data: ICreateUser, params?: RequestParams) =>
    this.http.request<IUser>({
      path: "/supervisors",
      method: "POST",
      secure: true,
      body: data,
      ...params,
    });

  updateSupervisor = (
    id: number,
    data: ICreateUser,
    params?: RequestParams
  ) =>
    this.http.request<IUser>({
      path: `/supervisors/${id}`,
      method: "PUT",
      body: data,
      secure: true,
      ...params,
    });

  deleteSupervisor = (id: number, params?: RequestParams) =>
    this.http.request<IUser>({
      path: `/supervisors/${id}`,
      method: "DELETE",
      secure: true,
      ...params,
    });
}

export default SupervisorsService;
