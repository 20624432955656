import { IStudentCustodianQuery } from "../models/studentCustodian/query";
import { ICreateIStudentCustodian, IUpdateIStudentCustodian } from "../models/studentCustodian/request";
import { IIStudentCustodian, IStudentCustodianDetails } from "../models/studentCustodian/response";
import IBaseListingResponse from "../utils/api/base-listing-response";
import { HttpClient, RequestParams } from "./http-client";

export class StudentCustodiansService<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  getAllStudentCustodians= (query: IStudentCustodianQuery, params?: RequestParams) =>
    this.http.request<IBaseListingResponse<IIStudentCustodian>>({
      path: `/student-custodian`,
      method: "GET",
      query,
      secure: true,
      ...params,
    });

  getStudentCustodian = (id: number, params?: RequestParams) =>
    this.http.request<IStudentCustodianDetails>({
      path: `/student-custodian/${id}`,
      method: "GET",
      secure: true,
      ...params,
    });

  createStudentCustodian = (data: ICreateIStudentCustodian, params?: RequestParams) =>
    this.http.request<IIStudentCustodian>({
      path: "/student-custodian",
      method: "POST",
      secure: true,
      body: data,
      ...params,
    });

  updateStudentCustodian = (
    id: number,
    data: IUpdateIStudentCustodian,
    params?: RequestParams
  ) =>
    this.http.request<IIStudentCustodian>({
      path: `/student-custodian/${id}`,
      method: "PUT",
      body: data,
      secure: true,
      ...params,
    });

  deleteStudentCustodian = (id: number, params?: RequestParams) =>
    this.http.request<IIStudentCustodian>({
      path: `/student-custodian/${id}`,
      method: "DELETE",
      secure: true,
      ...params,
    });
}

export default StudentCustodiansService;
