import { combineReducers, configureStore } from "@reduxjs/toolkit";
import UserReducer from "./user";
import AppReducer from "./app";
import authSlice from "./authSlice";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
// import { authApi, homeApi, generalApi, clientApi, carsApi } from "src/data";

const reducers = combineReducers({
  user: UserReducer,
  app: AppReducer,
  auth: authSlice,
  // [authApi.reducerPath]: authApi.reducer,
  // [homeApi.reducerPath]: homeApi.reducer,
  // [generalApi.reducerPath]: generalApi.reducer,
  // [clientApi.reducerPath]: clientApi.reducer,
  // [carsApi.reducerPath]: carsApi.reducer,
});

const Store = configureStore({
  reducer: reducers,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({}).concat([
      // authApi.middleware,
      // generalApi.middleware,
      // homeApi.middleware,
      // clientApi.middleware,
      // carsApi.middleware,
    ]),
});

export type RootState = ReturnType<typeof Store.getState>;
export type AppDispatch = typeof Store.dispatch; 
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;
export default Store;
