import { ICalanderQuery } from "../models/calander/query";
import { ICreateCalander, IUpdateCalander } from "../models/calander/request";
import { ICalander, ICalanderDetails } from "../models/calander/response";
import IBaseListingResponse from "../utils/api/base-listing-response";
import { HttpClient, RequestParams } from "./http-client";

export class CalanderService<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  getAllCalander = (query: ICalanderQuery, params?: RequestParams) =>
    this.http.request<IBaseListingResponse<ICalander>>({
      path: `/calendar`,
      method: "GET",
      query,
      secure: true,
      ...params,
    });

  getCalander = (id: number, params?: RequestParams) =>
    this.http.request<ICalanderDetails>({
      path: `/calendar/${id}`,
      method: "GET",
      secure: true,
      ...params,
    });

  createCalander = (data: ICreateCalander, params?: RequestParams) =>
    this.http.request<ICalander>({
      path: "/calendar",
      method: "POST",
      secure: true,
      body: data,
      ...params,
    });

  updateCalander = (id: number, data: IUpdateCalander, params?: RequestParams) =>
    this.http.request<ICalander>({
      path: `/calendar/${id}`,
      method: "PUT",
      body: data,
      secure: true,
      ...params,
    });

  deleteCalander = (id: number, params?: RequestParams) =>
    this.http.request<ICalander>({
      path: `/calendar/${id}`,
      method: "DELETE",
      secure: true,
      ...params,
    });
}

export default CalanderService;
