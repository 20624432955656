import { EventEmitter } from "events";

export const EVENT_UNAUTHORIZED = "event-unauthorized";
export const EVENT_FORBIDDEN = "event-forbidden";
export const EVENT_SUCCESS = "event-success";
export const EVENT_ERROR_NOTIFICATION = "error-notification";
export const EVENT_MESSAGES_SCROLL_TO_BOTTOM = "messages-scroll-to-bottom";
export const EVENT_SERVER_ERROR = "server-error";

const eventManager = new EventEmitter();

export default eventManager;
