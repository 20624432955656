import { IBuildingQuery } from "../models/buildings/query";
import { ICreateBuilding, IUpdateBuilding } from "../models/buildings/request";
import { IBuilding, IBuildingDetails } from "../models/buildings/response";
import IBaseListingResponse from "../utils/api/base-listing-response";
import { HttpClient, RequestParams } from "./http-client";

export class BuildingsService<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  getAllBuildings= (query: IBuildingQuery, params?: RequestParams) =>
    this.http.request<IBaseListingResponse<IBuilding>>({
      path: `/buildings`,
      method: "GET",
      query,
      secure: true,
      ...params,
    });

  getBuilding = (id: number, params?: RequestParams) =>
    this.http.request<IBuildingDetails>({
      path: `/buildings/${id}`,
      method: "GET",
      secure: true,
      ...params,
    });

  createBuilding = (data: ICreateBuilding, params?: RequestParams) =>
    this.http.request<IBuilding>({
      path: "/buildings",
      method: "POST",
      secure: true,
      body: data,
      ...params,
    });

  updateBuilding = (
    id: number,
    data: IUpdateBuilding,
    params?: RequestParams
  ) =>
    this.http.request<IBuilding>({
      path: `/buildings/${id}`,
      method: "PUT",
      body: data,
      secure: true,
      ...params,
    });

  deleteBuilding = (id: number, params?: RequestParams) =>
    this.http.request<IBuilding>({
      path: `/buildings/${id}`,
      method: "DELETE",
      secure: true,
      ...params,
    });
}

export default BuildingsService;
