import { IQualificationsUserQuery } from "../models/qualificationsUser/query";
import { ICreateQualificationsUser, IUpdateQualificationsUser } from "../models/qualificationsUser/request";
import { IQualificationsUser, IQualificationsUserDetails } from "../models/qualificationsUser/response";
import IBaseListingResponse from "../utils/api/base-listing-response";
import { HttpClient, RequestParams } from "./http-client";

export class QualificationUserService<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  getAllQualificationsUser = (query: IQualificationsUserQuery, params?: RequestParams) =>
    this.http.request<IBaseListingResponse<IQualificationsUserQuery>>({
      path: `/user-qualifications`,
      method: "GET",
      query,
      secure: true,
      ...params,
    });

  getQualificationUser = (id: number, params?: RequestParams) =>
    this.http.request<IQualificationsUserDetails>({
      path: `/user-qualifications/${id}`,
      method: "GET",
      secure: true,
      ...params,
    });

  createQualificationUser = (data: ICreateQualificationsUser, params?: RequestParams) =>
    this.http.request<IQualificationsUser>({
      path: "/user-qualifications",
      method: "POST",
      secure: true,
      body: data,
      ...params,
    });

  updateQualification = (
    id: number,
    data: IUpdateQualificationsUser,
    params?: RequestParams
  ) =>
    this.http.request<IQualificationsUser>({
      path: `/user-qualifications/${id}`,
      method: "PUT",
      body: data,
      secure: true,
      ...params,
    });

  deleteQualificationUser = (id: number, params?: RequestParams) =>
    this.http.request<IQualificationsUser>({
      path: `/user-qualifications/${id}`,
      method: "DELETE",
      secure: true,
      ...params,
    });
}

export default QualificationUserService;
