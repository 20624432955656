import { IProcedureQuery } from "../models/procedures/query";
import { ICreateProcedure, IUpdateProcedure } from "../models/procedures/request";
import { IProcedure, IProcedureDetails } from "../models/procedures/response";
import { ISchooleQuery } from "../models/schoole/query";
import { ICreateSchoole, IUpdateSchoole } from "../models/schoole/request";
import { ISchoole, ISchooleDetails } from "../models/schoole/response";
import IBaseListingResponse from "../utils/api/base-listing-response";
import { HttpClient, RequestParams } from "./http-client";


export class ProceduresService<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  getAllProcedures = (query: IProcedureQuery, params?: RequestParams) =>
    this.http.request<IBaseListingResponse<IProcedure>>({
      path: `/procedures`,
      method: "GET",
      query,
      secure: true,
      ...params,
    });

  getProcedure = (id: number, params?: RequestParams) =>
    this.http.request<IProcedureDetails>({
      path: `/procedures/${id}`,
      method: "GET",
      secure: true,
      ...params,
    });

  createProcedure = (data: ICreateProcedure, params?: RequestParams) =>
    this.http.request<IProcedure>({
      path: "/procedures",
      method: "POST",
      body: data,
      secure: true,
      ...params,
    });

  updateProcedure = (
    id: number,
    data: IUpdateProcedure,
    params?: RequestParams
  ) =>
    this.http.request<IProcedure>({
      path: `/procedures/${id}`,
      method: "PUT",
      secure: true,
      body: data,
      ...params,
    });

  deleteProcedure = (id: number, params?: RequestParams) =>
    this.http.request<IProcedure>({
      path: `/procedures/${id}`,
      method: "DELETE",
      secure: true,
      ...params,
    });
}

export default ProceduresService;
