import { ISemesterQuery } from "../models/semesters/query";
import { ICreateSemester, IUpdateSemester } from "../models/semesters/request";
import { ISemester, ISemesterDetails } from "../models/semesters/response";
import IBaseListingResponse from "../utils/api/base-listing-response";
import { HttpClient, RequestParams } from "./http-client";

export class SemesterService<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  getAllSemesters = (query: ISemesterQuery, params?: RequestParams) =>
    this.http.request<IBaseListingResponse<ISemester>>({
      path: `/semesters`,
      method: "GET",
      query,
      secure: true,
      ...params,
    });

  getSemester = (id: number, params?: RequestParams) =>
    this.http.request<ISemesterDetails>({
      path: `/semesters/${id}`,
      method: "GET",
      secure: true,
      ...params,
    });

  createSemester = (data: ICreateSemester, params?: RequestParams) =>
    this.http.request<ISemester>({
      path: "/semesters",
      method: "POST",
      secure: true,
      body: data,
      ...params,
    });

  updateSemester = (
    id: number,
    data: IUpdateSemester,
    params?: RequestParams
  ) =>
    this.http.request<ISemester>({
      path: `/semesters/${id}`,
      method: "PUT",
      body: data,
      secure: true,
      ...params,
    });

  deleteSemester = (id: number, params?: RequestParams) =>
    this.http.request<ISemester>({
      path: `/semesters/${id}`,
      method: "DELETE",
      secure: true,
      ...params,
    });
}

export default SemesterService;
