import { IBaseQuery } from "../models/base/base-query";
import { ICreateBook, IUpdateBook } from "../models/books/request";
import { IBook, IBookDetails } from "../models/books/response";
import IBaseListingResponse from "../utils/api/base-listing-response";
import { HttpClient, RequestParams } from "./http-client";

export class BooksService<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  getAllBooks= (query: IBaseQuery, params?: RequestParams) =>
    this.http.request<IBaseListingResponse<IBook>>({
      path: `/books`,
      method: "GET",
      query,
      secure: true,
      ...params,
    });

  getBook = (id: number, params?: RequestParams) =>
    this.http.request<IBookDetails>({
      path: `/books/${id}`,
      method: "GET",
      secure: true,
      ...params,
    });

  createBook = (data: ICreateBook, params?: RequestParams) =>
    this.http.request<IBook>({
      path: "/books",
      method: "POST",
      secure: true,
      body: data,
      ...params,
    });

  updateBook = (
    id: number,
    data: IUpdateBook,
    params?: RequestParams
  ) =>
    this.http.request<IBook>({
      path: `/books/${id}`,
      method: "PUT",
      body: data,
      secure: true,
      ...params,
    });

  deleteBook = (id: number, params?: RequestParams) =>
    this.http.request<IBook>({
      path: `/books/${id}`,
      method: "DELETE",
      secure: true,
      ...params,
    });
}

export default BooksService;
