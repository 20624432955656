import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import {  LANGUAGE_CODE } from "../utils/constants";

export interface AppState {
  isLoading: boolean;
  alert: {
    severity: "error" | "info" | "success";
    content: string;
    isOpen: boolean;
  };
  language: any;
  theme: 'light' | 'dark'
}

const initialState: AppState = {
  isLoading: false,
  alert: {
    severity: "error",
    content: "",
    isOpen: false,
  },
  language: localStorage.getItem(LANGUAGE_CODE) ?? "ar",
  theme:"light",
};

export const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    setIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setAlert: (
      state,
      action: PayloadAction<{
        severity: "error" | "info" | "success";
        content: string;
        isOpen: boolean;
      }>
    ) => {
      state.alert = action.payload;
    },
    setLanguage: (state, action: PayloadAction<any>) => {
      state.language = action.payload;
      localStorage.setItem(LANGUAGE_CODE, action.payload);
    },
    setTheme: (state, action: PayloadAction<any>) => {
      state.theme = action.payload;
    },
  },
});

export const { setIsLoading, setAlert, setLanguage ,setTheme } = appSlice.actions;

export default appSlice.reducer;
