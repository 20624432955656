import { IHallTypeQuery } from "../models/hallType/query";
import { ICreateHallType, IUpdateHallType } from "../models/hallType/request";
import { IHallType, IHallTypeDetails } from "../models/hallType/response";
import IBaseListingResponse from "../utils/api/base-listing-response";
import { HttpClient, RequestParams } from "./http-client";

export class HallTypesService<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  getAllHallTypes= (query: IHallTypeQuery, params?: RequestParams) =>
    this.http.request<IBaseListingResponse<IHallType>>({
      path: `/hall-types`,
      method: "GET",
      query,
      secure: true,
      ...params,
    });

  getHallType = (id: number, params?: RequestParams) =>
    this.http.request<IHallTypeDetails>({
      path: `/hall-types/${id}`,
      method: "GET",
      secure: true,
      ...params,
    });

  createHallType = (data: ICreateHallType, params?: RequestParams) =>
    this.http.request<IHallType>({
      path: "/hall-types",
      method: "POST",
      secure: true,
      body: data,
      ...params,
    });

  updateHallType = (
    id: number,
    data: IUpdateHallType,
    params?: RequestParams
  ) =>
    this.http.request<IHallType>({
      path: `/hall-types/${id}`,
      method: "PUT",
      body: data,
      secure: true,
      ...params,
    });

  deleteHallType  = (id: number, params?: RequestParams) =>
    this.http.request<IHallType>({
      path: `/hall-types/${id}`,
      method: "DELETE",
      secure: true,
      ...params,
    });
}

export default HallTypesService;
