import { ISkillQuery } from "../models/skills/query";
import { ICreateISkill, IUpdateISkill } from "../models/skills/request";
import { ISkill, ISkillDetails } from "../models/skills/response";
import IBaseListingResponse from "../utils/api/base-listing-response";
import { HttpClient, RequestParams } from "./http-client";

export class SkillsService<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  getAllSkills= (query: ISkillQuery, params?: RequestParams) =>
    this.http.request<IBaseListingResponse<ISkill>>({
      path: `/skills`,
      method: "GET",
      query,
      secure: true,
      ...params,
    });

  getSkill = (id: number, params?: RequestParams) =>
    this.http.request<ISkillDetails>({
      path: `/skills/${id}`,
      method: "GET",
      secure: true,
      ...params,
    });

  createSkill = (data: ICreateISkill, params?: RequestParams) =>
    this.http.request<ISkill>({
      path: "/skills",
      method: "POST",
      secure: true,
      body: data,
      ...params,
    });

  updateSkill = (
    id: number,
    data: IUpdateISkill,
    params?: RequestParams
  ) =>
    this.http.request<ISkill>({
      path: `/skills/${id}`,
      method: "PUT",
      body: data,
      secure: true,
      ...params,
    });

  deleteSkill = (id: number, params?: RequestParams) =>
    this.http.request<ISkill>({
      path: `/skills/${id}`,
      method: "DELETE",
      secure: true,
      ...params,
    });
}

export default SkillsService;
