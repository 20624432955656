import { IEvaluationSystemQuery } from "../models/evaluationSystems/query";
import { ICreateEvaluationSystem, IUpdateEvaluationSystem } from "../models/evaluationSystems/request";
import { IEvaluationSystem, IEvaluationSystemDetails } from "../models/evaluationSystems/response";
import IBaseListingResponse from "../utils/api/base-listing-response";
import { HttpClient, RequestParams } from "./http-client";

export class EvaluationSystemsService<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  getAllEvaluationSystems = (query: IEvaluationSystemQuery, params?: RequestParams) =>
    this.http.request<IBaseListingResponse<IEvaluationSystem>>({
      path: `/evaluation-system`,
      method: "GET",
      query,
      secure: true,
      ...params,
    });

  getEvaluationSystem = (id: number, params?: RequestParams) =>
    this.http.request<IEvaluationSystemDetails>({
      path: `/evaluation-system/${id}`,
      method: "GET",
      secure: true,
      ...params,
    });

  createEvaluationSystem = (data: ICreateEvaluationSystem, params?: RequestParams) =>
    this.http.request<IEvaluationSystem>({
      path: "/evaluation-system",
      method: "POST",
      secure: true,
      body: data,
      ...params,
    });

  updateEvaluationSystem = (
    id: number,
    data: IUpdateEvaluationSystem,
    params?: RequestParams
  ) =>
    this.http.request<IEvaluationSystem>({
      path: `/evaluation-system/${id}`,
      method: "PUT",
      body: data,
      secure: true,
      ...params,
    });

  deleteEvaluationSystem= (id: number, params?: RequestParams) =>
    this.http.request<IEvaluationSystem>({
      path: `/evaluation-system/${id}`,
      method: "DELETE",
      secure: true,
      ...params,
    });
}

export default EvaluationSystemsService;
