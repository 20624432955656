import { ISchooleQuery } from "../models/schoole/query";
import { ICreateSchoole, IUpdateSchoole } from "../models/schoole/request";
import { ISchoole, ISchooleDetails } from "../models/schoole/response";
import IBaseListingResponse from "../utils/api/base-listing-response";
import { HttpClient, RequestParams } from "./http-client";


export class SchoolsService<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  getAllSchooles = (query: ISchooleQuery, params?: RequestParams) =>
    this.http.request<IBaseListingResponse<ISchoole>>({
      path: `/schools`,
      method: "GET",
      query,
      secure: true,
      ...params,
    });

  getSchool = (id: number, params?: RequestParams) =>
    this.http.request<ISchooleDetails>({
      path: `/schools/${id}`,
      method: "GET",
      secure: true,
      ...params,
    });

  createSchool = (data: ICreateSchoole, params?: RequestParams) =>
    this.http.request<ISchoole>({
      path: "/schools",
      method: "POST",
      body: data,
      secure: true,
      ...params,
    });

  updateSchool = (
    id: number,
    data: IUpdateSchoole,
    params?: RequestParams
  ) =>
    this.http.request<ISchoole>({
      path: `/schools/${id}`,
      method: "PUT",
      secure: true,
      body: data,
      ...params,
    });

  deleteSchool = (id: number, params?: RequestParams) =>
    this.http.request<ISchoole>({
      path: `/schools/${id}`,
      method: "DELETE",
      secure: true,
      ...params,
    });
}

export default SchoolsService;
