import { IUserQuery } from "../models/users/query";
import { IUpdateUser } from "../models/users/request";
import { IUser, IUserDetails } from "../models/users/response";
import IBaseListingResponse from "../utils/api/base-listing-response";
import { HttpClient, RequestParams } from "./http-client";

export class UsersService<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  getAllUsers = (query: IUserQuery, params?: RequestParams) =>
    this.http.request<IBaseListingResponse<IUser>>({
      path: `/users`,
      method: "GET",
      query,
      secure: true,
      ...params,
    });

  getUser = (id: number, params?: RequestParams) =>
    this.http.request<IUserDetails>({
      path: `/users/${id}`,
      method: "GET",
      secure: true,
      ...params,
    });

  updateUser = (id: number, data: IUpdateUser, params?: RequestParams) =>
    this.http.request<IUser>({
      path: `/users/${id}`,
      method: "PUT",
      body: data,
      secure: true,
      ...params,
    });
}

export default UsersService;
