import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import { Spin } from "antd";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";

import { store } from "./state";
import { I18nextProvider } from "react-i18next";
import i18n from "./utils/localization/i18n";
import AntdConfigProvider from "./components/general/AntdProvider/AntdProvider";

const LazyComponent = React.lazy(() => import("./App"));

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Provider store={store}>
        <I18nextProvider i18n={i18n}>
          <AntdConfigProvider>
            <Suspense
              fallback={
                <div className="spinner-container">
                  <Spin size="default" />
                </div>
              }
            >
              <LazyComponent />
            </Suspense>
          </AntdConfigProvider>
        </I18nextProvider>
      </Provider>
    </BrowserRouter>
  </React.StrictMode>
);
