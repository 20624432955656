import { ISubjectQuery } from "../models/subjects/query";
import { ICreateSubject, IUpdateSubject } from "../models/subjects/request";
import { ISubject, ISubjectDetails } from "../models/subjects/response";
import IBaseListingResponse from "../utils/api/base-listing-response";
import { HttpClient, RequestParams } from "./http-client";

export class SubjectsService<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  getAllSubjects= (query: ISubjectQuery, params?: RequestParams) =>
    this.http.request<IBaseListingResponse<ISubject>>({
      path: `/subjects`,
      method: "GET",
      query,
      secure: true,
      ...params,
    });

  getSubject = (id: number, params?: RequestParams) =>
    this.http.request<ISubjectDetails>({
      path: `/subjects/${id}`,
      method: "GET",
      secure: true,
      ...params,
    });

  createSubject = (data: ICreateSubject, params?: RequestParams) =>
    this.http.request<ISubject>({
      path: "/subjects",
      method: "POST",
      secure: true,
      body: data,
      ...params,
    });

  updateSubject = (
    id: number,
    data: IUpdateSubject,
    params?: RequestParams
  ) =>
    this.http.request<ISubject>({
      path: `/subjects/${id}`,
      method: "PUT",
      body: data,
      secure: true,
      ...params,
    });

  deleteSubject = (id: number, params?: RequestParams) =>
    this.http.request<ISubject>({
      path: `/subjects/${id}`,
      method: "DELETE",
      secure: true,
      ...params,
    });
}

export default SubjectsService;
