import { IClassroomEvaluationComponentQuery } from "../models/classroomEvaluationComponent/query";
import { ICreateClassroomEvaluationComponent, IUpdateClassroomEvaluationComponent } from "../models/classroomEvaluationComponent/request";
import { IClassroomEvaluationComponent, IClassroomEvaluationComponentDetails } from "../models/classroomEvaluationComponent/response";
import IBaseListingResponse from "../utils/api/base-listing-response";
import { HttpClient, RequestParams } from "./http-client";

export class ClassroomEvaluationComponentsService<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  getAllClassroomEvaluationComponents = (query: IClassroomEvaluationComponentQuery, params?: RequestParams) =>
    this.http.request<IBaseListingResponse<IClassroomEvaluationComponent>>({
      path: `/classroom-evaluation-components`,
      method: "GET",
      query,
      secure: true,
      ...params,
    });

  getClassroomEvaluationComponent = (id: number, params?: RequestParams) =>
    this.http.request<IClassroomEvaluationComponentDetails>({
      path: `/classroom-evaluation-components/${id}`,
      method: "GET",
      secure: true,
      ...params,
    });

  createClassroomEvaluationComponent = (data: ICreateClassroomEvaluationComponent, params?: RequestParams) =>
    this.http.request<IClassroomEvaluationComponent>({
      path: "/classroom-evaluation-components",
      method: "POST",
      secure: true,
      body: data,
      ...params,
    });

  updateClassroomEvaluationComponent = (
    id: number,
    data: IUpdateClassroomEvaluationComponent,
    params?: RequestParams
  ) =>
    this.http.request<IClassroomEvaluationComponent>({
      path: `/classroom-evaluation-components/${id}`,
      method: "PUT",
      body: data,
      secure: true,
      ...params,
    });

  deleteClassroomEvaluationComponent = (id: number, params?: RequestParams) =>
    this.http.request<IClassroomEvaluationComponent>({
      path: `/classroom-evaluation-components/${id}`,
      method: "DELETE",
      secure: true,
      ...params,
    });
}

export default ClassroomEvaluationComponentsService;
