enum ApiErrorType {
  CONNECTION = 'Connection',
  BAD_REQUEST = 'Bad Request',
  UNAUTHORIZED = 'Unauthorized',
  FORBIDDEN = 'Forbidden',
  NOT_FOUND = 'Not Found',
  CONFLICT = 'Conflict',
  INTERNAL_SERVER_ERROR = 'Internal Server Error',
  CUSTOM = 'Custom',
  UNKNOWN = 'Unknown',
}

export default ApiErrorType
