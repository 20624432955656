import i18next from "i18next";
import { HttpClient } from "../api/http-client";
import ApiError from "../utils/api/api-error";
import ApiErrorType from "../utils/api/api-error-type";

import eventManager, {
  EVENT_ERROR_NOTIFICATION,
  EVENT_FORBIDDEN,
  EVENT_UNAUTHORIZED,
} from "../utils/event-manager";
import { getToken } from "./authManager";
import { ACCESS_TOKEN, LANGUAGE_CODE } from "../utils/constants";
import { errorNotification } from "../utils/helpers/notification";

export const httpclient = new HttpClient<{ token?: string }>({
  securityWorker: (data: any) => {
    return {
      headers: {
        Authorization: data.token,
      },
    };
  },
  headers: {
    "Accept-Language": `${localStorage.getItem(LANGUAGE_CODE) ?? "ar"}`,
  },
  baseURL: `${process.env.REACT_APP_BASE_API_URL}`,
});

httpclient.instance.interceptors.request.use(
  (config) => {
    config.headers["Accept-Language"] = `${
      localStorage.getItem(LANGUAGE_CODE) ?? "ar"
    }`;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
httpclient.instance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    throw handleError(error);
  }
);

const handleError = (error: ApiError): ApiError => {
  let message: string;

  if (error.message !== "cancel_token") {
    if (error.response) {
      // The request was made and the server responded with an error status code.
      //@ts-ignore
      message = error.response.data.message;
      let type: ApiErrorType;
      switch (error.response.status) {
        case 400:
          type = ApiErrorType.BAD_REQUEST;
          break;
        case 401:
          type = ApiErrorType.UNAUTHORIZED;
          eventManager.emit(EVENT_UNAUTHORIZED);
          break;
        case 403:
          type = ApiErrorType.FORBIDDEN;
          eventManager.emit(EVENT_FORBIDDEN);
          break;
        case 404:
          type = ApiErrorType.NOT_FOUND;
          break;
        case 409:
          type = ApiErrorType.CONFLICT;
          break;
        case 500:
          type = ApiErrorType.INTERNAL_SERVER_ERROR;
          message = "500";
          break;
        default:
          type = ApiErrorType.UNKNOWN;
          break;
      }

      error.errorType = type;
    } else if (error.request) {
      // The request was made but no response was received.
      message = "something_wrong_message";
      error.errorType = ApiErrorType.CONNECTION;
    } else {
      message = "something_wrong_message";
      error.errorType = ApiErrorType.UNKNOWN;
    }
    // console.log('error', message)

    // Display Error
    errorNotification(error?.response?.data?.errors?.[0] ??  i18next.t(message));
  }

  return error;
};
