import { IStudenClassroomQuery } from "../models/studentsClassroom/query";
import { ICreateStudentClassroom, IUpdateStudentClassroom } from "../models/studentsClassroom/request";
import { IStudentClassroom, IStudentClassroomDetails, IStudentClassroomHistory } from "../models/studentsClassroom/response";
import IBaseListingResponse from "../utils/api/base-listing-response";
import { HttpClient, RequestParams } from "./http-client";

export class StudentsClassroomService<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  getAllStudentsClassroom= (query: IStudenClassroomQuery, params?: RequestParams) =>
    this.http.request<IBaseListingResponse<IStudentClassroom>>({
      path: `/student-classroom`,
      method: "GET",
      query,
      secure: true,
      ...params,
    });

    getHistoryClassroomStudent= (query: IStudenClassroomQuery, params?: RequestParams) =>
      this.http.request<IBaseListingResponse<IStudentClassroomHistory>>({
        path: `/student-classroom-history`,
        method: "GET",
        query,
        secure: true,
        ...params,
      });

  getStudentClassroom = (id: number, params?: RequestParams) =>
    this.http.request<IStudentClassroomDetails>({
      path: `/student-classroom/${id}`,
      method: "GET",
      secure: true,
      ...params,
    });

  createStudentClassroom = (data: ICreateStudentClassroom, params?: RequestParams) =>
    this.http.request<IStudentClassroom>({
      path: "/student-classroom",
      method: "POST",
      secure: true,
      body: data,
      ...params,
    });

  updateStudentClassroom = (
    id: number,
    data: IUpdateStudentClassroom,
    params?: RequestParams
  ) =>
    this.http.request<IStudentClassroom>({
      path: `/student-classroom/${id}`,
      method: "PUT",
      body: data,
      secure: true,
      ...params,
    });

  deleteStudentClassroom = (id: number, params?: RequestParams) =>
    this.http.request<IStudentClassroom>({
      path: `/student-classroom/${id}`,
      method: "DELETE",
      secure: true,
      ...params,
    });
}

export default StudentsClassroomService;
